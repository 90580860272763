import React from "react"
import './DeliveryBoy.css'

const DeliveryBoy= () =>{

    return(
        <div>

        </div>
    )
}

export default DeliveryBoy